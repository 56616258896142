import { Injectable } from '@angular/core';
import { PartApplication } from '../interface/part-application';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first, firstValueFrom } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private _isFromMatrix = true;


  applications: PartApplication[] = [];

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }

  public async getAll(): Promise<PartApplication[]>{
    const url = `${environment.mkgoURL}/api/v1/application`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    this.applications = (resp["applications"] || [] as PartApplication[]).reverse();
    return this.applications;
  }

  public async getById(id: string){
    const url = `${environment.mkgoURL}/api/v1/application/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp as PartApplication;
  }

  public async register(application: PartApplication){
    const url = `${environment.mkgoURL}/api/v1/application`;
    const apiApplication = this.complyApi(application);
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, JSON.stringify(apiApplication), header).pipe(first()).toPromise();
    return resp["id"];
  }

  public async update(application: PartApplication){
    const url = `${environment.mkgoURL}/api/v1/application/${application._id}`;
    const apiApplication = this.complyApi(application);
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, JSON.stringify(apiApplication), header).pipe(first()).toPromise();
    return resp;
  }

  public async delete(id: string){
    const url = `${environment.mkgoURL}/api/v1/application/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.delete(url, header).pipe(first()).toPromise();
    return resp;
  }

  private complyApi (application: PartApplication){
    return {
      "description": application.description
    }
  }


}
