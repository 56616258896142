<h1 mat-dialog-title>
    {{ (brand ? 'ACTION.PART_BRAND.UPDATE' : 'ACTION.PART_BRAND.ADD') | translate }}
</h1>
<form [formGroup]="form"
      autocomplete="off">
    <mat-dialog-content class="item-dialog">

        <!-- description -->
        <mat-form-field class="col-12">
            <input matInput
                   formControlName="description"
                   placeholder="Descrição"
                   required>
        </mat-form-field>


    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>